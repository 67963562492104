<template>
  <div class="m-0 p-0 container">
    <div class="d-flex items-center position-relative">
      <CInput
        class="m-0 w-100"
        placeholder="جستجوی زمین..."
        v-model="myfilter"
        @input="searchFilter"
      />
      <CIcon name="cilSearch" class="search-icon position-absolute"/>
    </div>
    <div
      class="my-1 mr-1 d-md-none"
      style="max-height: 12rem; overflow-y: auto; overflow-x: hidden"
    >
      <button
        v-for="(item, index) of filteredList"
        :key="index"
        :class="
          selectedFarm != null && selectedFarm.value == item.value
            ? 'actived'
            : ''
        "
        class="text-center p-0"
        style=""
        @click="printFarm(item)"
      >
        {{ item.text }}
      </button>
    </div>

    <div
      class="my-1 mr-1"
      style="max-height: 12rem; overflow-y: auto; overflow-x: hidden"
    >
      <button
        class="add-farm-btn y-center-g-5 mb-2 px-3 justify-content-between text-primary"
        @click="
          $router.push({
            name: 'ثبت اطلاعات زمین با نقشه',
          })
        "
        v-if="isAddFarmAvailable"
      >
        <span>افزودن زمین جدید</span>
        <CIcon name="cil-plus"/>
      </button>
      <button
        v-for="(item, index) of filteredList"
        :key="index"
        class="text-center bg-transparent d-flex justify-content-between align-items-center px-3"
        :class="{
          'text-secondary':selectedFarm != null && selectedFarm.value === item.value
        }"
        @click="printFarm(item)"
      >
        <span>
          <CIcon name="cil-check" class="text-secondary ml-2" v-if="selectedFarm != null && selectedFarm.value == item.value"/>
          <span>{{
              (selectedFarm?.value === item?.value && selectFarmLoading) ? "در حال دریافت اطلاعات..." : item.text
            }}</span>
        </span>
        <!-- <font-awesome-icon icon="angle-left" class="text-black-50" /> -->
      </button>

    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  data() {
    return {
      selectedFarm: null,
      myfilter: "",
      filteredList: [],
    };
  },
  props: {
    farmsNameList: {
      type: Array,
    }, 
    selectFarmLoading: {
      type: Boolean,
      default: true,
    },
    farmersNameList: {
      type: Array,
    },
  },
  computed: {
    ...mapState({
      DisplayFarmUser: (state) =>
        state.login.type == null ? state.register.type : state.login.type,
    }),
    getFarmsNameList() {
      return this.farmsNameList;
    },
  },
  mounted() {
    this.filteredList = [];
    this.filteredList = this.getFarmsNameList;
  },
  watch: {
    farmsNameList: function () {
      this.filteredList = this.getFarmsNameList;
    },
  },
  methods: {
    isAddFarmAvailable() {
      switch (
        this.DisplayFarmUser
          ? this.DisplayFarmUser.toLowerCase()
          : this.DisplayFarmUser
        ) {
        case "farmer":
          return true; 
        case "farmuser":
          return false;
        case "advicer":
          return false;
        case "agency":
          return false; 
        default :
          return false;
      }
    },
    printFarm(item) {
      this.selectedFarm = item;
      this.$emit("selectFarm", this.selectedFarm);
    },
    searchFilter() {
      try {
        if (this.myfilter && this.myfilter.trim()) {
          this.filteredList = this.getFarmsNameList.filter((filter) =>
            filter.text.includes(this.myfilter)
          );
        } else {
          this.filteredList = this.getFarmsNameList;
        }
      } catch (error) {
        console.log(error);
      }
    }, 
  },
};
</script>
<style scoped>
button {
  height: 2rem;
  width: 100%;
  border: none;
  margin-top: 2px;
}

.add-farm-btn {
  padding: 22px 0;
  position: sticky;
  z-index: 2;
  top: 0;
  transition: all .3s;
  background: #fff;
}

.add-farm-btn:hover {
  background: var(--primary-light);
  color: #fff !important;
}

button:hover {
  color: var(--primary);
}

.search-icon {
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
